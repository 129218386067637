@import "/src/styles/variables.scss";

.container {
	margin: 0 auto;
	max-width: $M;
}

@media screen and (max-width: $M) {
	.container {
		margin: 0 auto;
		max-width: 100%;
	}
}
