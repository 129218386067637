@import "/src/styles/variables.scss";

.cardContainer {
	background-color: $white;
	position: relative;
	border-radius: $borderRNormal;
	transition: all 0.3s;
	overflow: hidden;
}

.cardContentBox {
	text-align: center;
	padding: 20px;
}

.cardtitle {
	color: $brandColor;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	margin: auto 0;
	position: absolute;
	padding: 0 10px;
	transition: 0.5s;
	z-index: 11;
	max-height: 25%;
	height: 25%;
}

.cardDesc {
	bottom: 0;
	left: 0;
	position: absolute;
	padding: 0 10px;
	transition: 0.5s;
	z-index: 11;
	max-height: 0;
	height: 0;
	opacity: 0;
}

.cardContainer:hover {
	cursor: pointer;

	& .cardDesc {
		max-height: 60%;
		height: 60%;
		opacity: 1;
		transition: 0.5s;
	}

	& .cardtitle {
		max-height: 75%;
		height: 75%;
		transition: 0.5s;
	}
}

@media screen and (max-width: $M) {
	.cardContentBox {
		height: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: $flexGap8;
	}

	.cardContainer:hover {
		cursor: unset;
		border-radius: $borderRFull;

		& .cardDesc {
			max-height: unset;
			height: unset;
		}

		& .cardtitle {
			max-height: unset;
			height: unset;
		}
	}

	.cardtitle {
		position: relative;
		padding: 0;
		max-height: 100%;
		height: auto;
		margin: unset;
	}

	.cardDesc {
		position: relative;
		padding: 0;
		max-height: 100%;
		height: auto;
		opacity: 1;
		margin: unset;
	}
}
