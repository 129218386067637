$XL: 1280px;
$L: 1023px;
$M: 767px;

$mobileCardMaxWidth: 450px;

@mixin singleMobMaxWidth() {
	flex: 1 0 100%;
	max-width: 450px;
	margin: 0 auto;
}
