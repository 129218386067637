@import "/src/styles/variables.scss";

.countBox {
	@include flex(6, $flexGap24);
}

.container {
	display: flex;
	gap: $flexGap24;
}

@media screen and (max-width: $L) {
	.countBox {
		@include flex(3, $flexGap24);
	}

	.container {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: $M) {
	.countBox {
		@include flex(2, $flexGap24);
	}
}
