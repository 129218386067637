@mixin cardsAdditional($items) {
	.cardContainer {
		display: flex;
		gap: $flexGap24;
	}

	.cardBox {
		@include flex($items, $flexGap24);
	}

	@media screen and (max-width: $L) {
		.cardBox {
			@include flex($items - 1, $flexGap24);
		}

		.cardBox:last-child {
			display: none;
		}
	}

	@media screen and (max-width: $M) {
		.cardContainer {
			flex-wrap: wrap;
			gap: $flexGap64;
		}

		.cardBox {
			@include singleMobMaxWidth();
		}

		.cardBox:last-child {
			// display: block;
		}
	}
}

@mixin cardsImportant(
	$items,
	$withGap: true,
	$withWrap: false,
	$withAlign: false,
	$justify: space-between,
	$align: start
) {
	$smallGap: if($withGap, if($items < 3, $flexGap48, $flexGap24), 0);
	$wrap: if($withWrap, wrap, nowrap);
	$alignItems: if($withAlign, $align, unset);

	.cardContainer {
		display: flex;
		gap: $smallGap;
		justify-content: $justify;
		flex-wrap: $wrap;
		align-items: $alignItems;
	}

	.cardBox {
		@include flex($items, $smallGap);
	}

	@media screen and (max-width: $L) {
		.cardContainer {
			flex-wrap: wrap;
			gap: $smallGap * 2;

			@if ($items / 2==1) {
				justify-content: center;
			}
		}

		.cardBox {
			@if ($items % 2 !=0) {
				@include flex(($items + 1) / 2, $smallGap * 2);
			} @else {
				@include flex($items / 2, $smallGap * 2);
			}

			@if ($items / 2==1) {
				flex: 0 0 80%;
			}
		}
	}

	@media screen and (max-width: $M) {
		.cardContainer {
			gap: $flexGap64;
		}

		.cardBox {
			@include singleMobMaxWidth();
		}
	}
}
