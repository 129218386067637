@import "/src/styles/variables.scss";

.videoBackground {
	min-height: 25rem;
	position: absolute;
	width: 100%;
	min-height: 100%;
	object-fit: cover;
	left: 0;
	bottom: 0;
	margin: auto 0;
	top: 0;
	z-index: -1;

	&.mobile {
		display: none;
	}
}

@media screen and (max-width: $M) {
	.videoBackground {
		&.withoutMobile {
			display: none;
		}

		&.mobile {
			display: block;
		}
	}
}
