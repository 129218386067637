@import "/src/styles/variables.scss";

.list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	position: relative;
	transition: 0.3s;

	// &:hover {
	//     transition: 0.3s;
	//     box-shadow: purple 0px 2px 8px;

	//     &:before {
	//         background-color: transparent;
	//     }

	// }

	// &:first-child:before {
	//     background-color: transparent;
	// }

	// &:before {
	//     background-color: $black;
	//     content: "";
	//     height: 1px;
	//     left: 0;
	//     margin: 0 auto;
	//     opacity: .2;
	//     position: absolute;
	//     right: 0;
	//     top: 0;
	//     width: 99%;
	// }

	&:hover {
		transition: 0.3s;
		box-shadow: 0 8px 6px -6px rgba(190, 0, 189, 0.6),
			0 -8px 6px -6px rgba(190, 0, 189, 0.6);

		&::before {
			background-color: transparent;
		}

		& + & {
			&::before {
				background-color: transparent;
			}
		}
	}

	&:hover + & {
		&::before {
			background-color: transparent;
		}
	}

	&::before {
		content: "";
		position: absolute;
		width: 99%;
		height: 1px;
		background-color: $black;
		opacity: 0.2;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&:first-child {
		&::before {
			background-color: transparent;
		}
	}
}

.linkContainer {
	display: flex;
	align-items: start;
	justify-content: space-between;
	flex: 1 0 calc(100% - 120px);
	gap: $flexGap8;
	padding: 8px 0;
}

.playButton {
	display: flex;
	align-items: center;
	flex: 1 0 60px;
	height: 100% !important;
	justify-content: center;
}

.text {
	padding: 0 !important;
	flex: 1 0 calc((100% - 15%) / 3 - 8px * 3 / 3);
}

.linkItem {
	flex: 1 0 calc(15% - 40px);
	min-width: fit-content;
	align-self: center;
}

@media screen and (max-width: $L) {
	.list {
		flex-wrap: wrap;
		align-items: end;
	}

	.linkContainer {
		flex-wrap: wrap;
		justify-content: start;
	}

	.playButton {
		margin-bottom: 22.5px;
	}

	.text {
		flex: 1 0 calc((100% - 15% - 40px) / 2 - 8px * 2);

		&:first-child {
			flex: 1 0 100%;
		}
	}
}

@media screen and (max-width: $M) {
	.text {
		flex: 1 0 100%;
	}

	.playButton {
		flex: 1;
	}
}
