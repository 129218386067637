@import "/src/styles/variables.scss";

.cardsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: $flexGap32;
}

.cardContainer {
	@include flex(3, $flexGap32);
	min-height: 170px;
}

@media screen and (max-width: $L) {
	.cardContainer {
		@include flex(2, $flexGap32);
		min-height: 200px;
	}
}

@media screen and (max-width: $M) {
	.cardContainer {
		@include singleMobMaxWidth();

		min-height: 200px;
	}
}
