@import "/src/styles/variables.scss";

@include cardsImportant(2, $withWrap: wrap, $justify: start);

.positions__container {
	padding: 25px 50px;
}

.title {
	text-align: center;
	margin-bottom: 50px;
}

.filters_container {
	position: relative;
	padding: 0 5% 60px;

	&::before {
		position: absolute;
		opacity: 0.1;
		content: "";
		height: 1px;
		width: 100%;
		background-color: $black;
		bottom: 0;
		left: 0;
	}
}

.select {
	flex: 0 0 calc(80% / 2 - 48px / 2);
}

.bottom {
	position: relative;

	&::before {
		position: absolute;
		opacity: 0.1;
		content: "";
		height: 1px;
		width: 100%;
		background-color: $black;
		top: 0;
		left: 0;
	}

	text-align: center;
}

.bottom__title {
	// padding: 50px 0 30px 0;
}

@media screen and (max-width: $L) {
	.select {
		@include flex(2, $flexGap48);
	}
}

@media screen and (max-width: $M) {
	.filters_container {
		padding: 0 5% 50px;
		gap: $flexGap16;
	}

	.select {
		flex: 1 0 100%;
	}

	.bottom {
		& button {
			padding: 10px !important;
		}
	}
}
