$purple: var(--purple);
$white: #fff;
$black: #000;

$grayScaleLvl1: #141414;
$grayScaleLvl2: #525252;
$grayScaleLvl3: #8f8f8f;
$grayScaleLvl4: #cccccc;
$grayScaleLvl5: #f5f5f5;
$grayScaleLvl6: #ffffff;

$brandColor: var(--brandColor);

$brandColorShadeLvl0: var(--brandColorShadeLvl0);
$brandColorShadeLvl1: var(--brandColorShadeLvl1);
$brandColorShadeLvl2: var(--brandColorShadeLvl2);
$brandColorShadeLvl3: var(--brandColorShadeLvl3);

$brandColorOne: #175eeb;
$blue: #175eeb;
$brandColorOneShadeLvl0: #1247b3;
$brandColorOneShadeLvl1: #1554d1;
$brandColorOneShadeLvl2: #739ef3;
$brandColorOneShadeLvl3: #9dbbf7;

$alertColorLvl1: #3ab4d6;
$alertColorLvl2: #fd6161;

$boxShadowNormal: rgba(0, 0, 0, 0.15) 0px 2px 8px;
$boxShadowHover: rgb(0 0 0 / 25%) 0px 2px 8px;
