@import "/src/styles/variables.scss";

.noBlogPostsFound {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 4rem;
	margin-bottom: 4rem;

	&__notFoundIllustration {
		height: 300px;
		width: 300px;
		animation: rotate 1.5s ease-in-out infinite;
	}

	&__noBlogPostsFoundTitle {
		font-size: 2rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

	&__noBlogPostsFoundText {
		font-size: 1.5rem;
		text-align: center;
		color: $purple;
		animation: slideUp 0.5s ease-out;
	}
}
