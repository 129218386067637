@import "/src/styles/variables.scss";

.list__container {
	width: 100%;
	position: relative;
	// &::before {
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	content: "";
	// 	background-color: $black;
	// 	opacity: 0.1;
	// 	height: 1px;
	// 	width: 100%;
	// }
}

.list {
	width: 100%;
	display: flex;
	flex-direction: column;
	// gap: 12px;
	justify-content: center;
}

@media screen and (max-width: $M) {
	.list {
		// gap: $flexGap48;
	}
}
