@import "/src/styles/variables.scss";

.backgroundVideo {
	overflow: hidden;
	width: 100%;
	position: relative;

	&.video {
		height: 0;
		padding-bottom: clamp(360px, 65vh, 900px);
		overflow: hidden;
	}

	&.image {
		height: 0;
		padding-bottom: clamp(360px, 65vh, 900px);
		overflow: hidden;
	}

	&.none {
		height: 25vh;
		min-height: 200px;
	}
}

.content {
	&__container {
		position: absolute;
		margin: 0 auto;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		z-index: 11;
	}
}

.video {
	color: $white;
}

.image {
	color: $white;
}

.none {
	color: $black;
}

.contentBox {
	width: 100%;
	display: flex;

	justify-content: left;
}

.video__content {
	width: 100%;
}

.video__content_buttons {
	display: flex;
	margin-top: 20px;
	flex-wrap: wrap;
	gap: $flexGap24;
	padding: 0;
	justify-content: start;
}

.title {
	max-width: 650px;
}

.subtitle {
	max-width: 500px;
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background-color: $black;
	opacity: 0.5;
	z-index: 10;
}

.titleContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 5%;
}

.titleBox {
	display: flex;
	align-items: center;
	flex: 1 0 40%;
}

.iconContainer {
	max-width: 200px;
}

.icon {
	width: 100%;
	height: auto;
}

.rightImageContainer {
	max-width: 750px;
	height: clamp(260px, 45vh, 700px);
	width: auto;
	margin: 0 auto;
	text-align: center;
	flex: 0 0 55%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rightImage {
	width: auto;
	height: auto;
	max-width: 750px;
	margin: 0 auto;
	max-height: 100%;
}

@media (max-width: $M) {
	.disableIcon {
		display: none;
	}

	.titleContainer {
		flex-direction: column;
	}

	.titleBox {
		flex-direction: column;
	}

	.video__content {
		flex: 1;
		text-align: center;
	}

	.video__content_buttons {
		max-width: 400px;
		margin: 1rem auto 0;

		& button {
			flex: 1 0 100%;
		}
	}

	.rightImage,
	.rightImageContainer {
		max-width: clamp(200px, 50%, 45vh);
	}
}
