@import "/src/styles/variables.scss";

.imageContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	right: 0;
	width: auto;
	min-width: 100%;
	min-height: 100%;
	object-fit: cover;
	max-width: 100%;
	height: auto;

	&.mobile {
		display: none;
	}
}

@media screen and (max-width: $M) {
	.imageContainer {
		&.withoutMobile {
			display: none;
		}

		&.mobile {
			display: block;
		}
	}
}
