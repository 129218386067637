@import "/src/styles/variables.scss";

.categoryInput {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.categoryTitle {
	position: relative;
	padding: 5px 10px;
	border-radius: $borderRNormal;
	background-color: $white;
	font-size: 16px;
	border: 1px solid #aaa;
	display: flex;
	height: 100%;
	align-items: center;
}

.categoryInput:checked + .categoryTitle {
	background-color: $brandColor;
	color: $white;
	border-color: $brandColor;
}

.divider {
	border-left: 1px solid black;
}

.categoryTitle:hover {
	background-color: $grayScaleLvl5;
}
.categoryMultiSelect {
	margin: 16px 0;
	gap: $flexGap8;
	display: flex;
	flex-wrap: wrap;
}

.categorySelectAll {
	position: relative;
	border-radius: $borderRNormal;
	background-color: $white;
	border: 1px solid $brandColor;
	padding: 0 10px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.categorySelectAll:hover {
	background-color: $white;
	color: $brandColor;
}

.categoryLabel {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: $black;
	cursor: pointer;
	position: relative;
}
