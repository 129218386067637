@import "/src/styles/variables.scss";

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: $flexGap8;
	margin: 0;
	padding: 0;

	.pageItem {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.pageLink {
		display: inline-block;
		padding: 4px 8px;
		font-size: 16px;
		text-align: center;
		color: $brandColor;
		background-color: $white;
		border-color: $brandColor;
		border: 1px solid $brandColor;
		border-radius: $borderRNormal;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: $white;
			background-color: $brandColor;
			border: 1px solid $brandColor;
			cursor: pointer;
		}

		&.active {
			color: $white;
			background-color: $brandColor;
			border: 1px solid $brandColor;
		}
	}
}
