$flexGap8: 8px;
$flexGap16: 16px;
$flexGap24: 24px;
$flexGap32: 32px;
$flexGap48: 48px;
$flexGap64: 64px;

$flexGap5P: 5%;

@mixin flex($items, $gap) {
	$gap-value: $gap * 1px;
	$gap-value: if(unitless($gap), $gap-value, $gap);
	flex: 0 0
		calc(100% / #{$items} - #{$gap-value} * (#{$items} - 1) / #{$items});
}
